import './App.css';

export default function App() {
  return (
    <div className="min-h-screen flex items-center justify-center">
      <h1 className="text-3xl font-bold underline">
        This app (Softbox) is a work in progress.
      </h1>
    </div>
  )
}